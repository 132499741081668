
import { Component, Vue, Prop, } from 'vue-property-decorator'

@Component
export default class ChipComponent extends Vue {
  @Prop({ required: false, default: 'default', }) status!: string
  @Prop({ required: false, default: 'default', }) size!: string
  @Prop({ required: false, default: false, }) disabled!: boolean
  @Prop({ required: false, default: false, }) pointer!: boolean
  @Prop({ required: false, }) icon!: string
  @Prop({ required: false, default: false, }) reverse!: boolean
  @Prop({ required: false, default: false, }) in_compliance!: boolean
  @Prop({ required: true, }) text!: string

  private value = ''

  selected () {
    this.$emit('selected')
  }
}
