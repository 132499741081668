
import { Component, Prop, Vue, } from 'vue-property-decorator'
import Avatar from 'vue-avatar'
import { ActivityInterface, } from '@/store/types/Activities/ActivitiesGeneral'
import ChipHackmetrix from '@/components/Chip/Index.vue'
import { mapGetters, } from 'vuex'

@Component({
  components: {
    ChipHackmetrix,
    avatar: Avatar,
  },
  computed: {
    ...mapGetters('ActivitiesModule', ['getSelectedActivity',]),
    ...mapGetters('ActivitiesModule', { selectedActivity: 'getSelectedActivity', }),
  },
})
export default class ActBox extends Vue {
  public selectedActivity: any
  private porcentaje = 75
  private role = this.$store.state.AuthModule.AuthState.role
  private seen = false

  @Prop({
    required: false,
    default: {
      id: 'ID',
      name: ' Greyhound divisively hello coldly wonderfully marginally far upon excluding. max 3 líneas',
      description: 'Titulo de la activida maximo 2 lineas',
    },
  })
  activity!: ActivityInterface

  private goTo () {
    this.$store.commit('ActivitiesModule/SET_SELECTED_ACTIVITY', [])
    const id: any = this.activity.id
    if (this.$route.path.includes('startup')){
      this.$mixpanel.track('Actividad seleccionada', { Actividad: this.activity.name, })
    }else{
      this.$mixpanel.track('Actividad seleccionada', { Actividad: this.activity.name, 'Empresa': this.$store.state.BusinessModule.BusinessState.businessSelected.name,})
    }
    const name = this.$route.path.includes('startup') ? 'activityDetailClient' : 'activityDetail'
    this.$router.push({ name, params: { task_id: id, }, })
  }
}
